
<app-top-header  class='top-bar-container' bgStyle="solid" screenTitle="{{'About Us'| translate}}"></app-top-header>
<div class="page-content px-4 my-4">
    <div class="align-self-center   cursor-pointer" [routerLink]="['/info']">
        <div class="who-we-are-logo text-center">
          <img src="../../assets/images/side-bg.jpg">
        </div>

   
      </div>
      <div class="content mt-3">
        <div [innerHTML]= "aboutUs|iknLocalized"></div>
      </div>
      <div class="content mt-3">
    </div>
</div>
<app-footer></app-footer>