<div class="top-bar" [ngClass]="{'solid' :  bgStyle=='solid'}">
  <div class=' d-flex flex-column'>
    <div class="px-1 d-flex align-items-center justify-content-between top-bar-header">
      <div class="d-flex align-items-center justify-content-center">
        <div>
          <!-- <a class="link-icon toggle-menu" >
            <i class="fa fa-bars toggle-menu"></i>
          </a> -->
          <a class="link-icon open" href="#sidenav">
            <i class="fa fa-bars"></i>
          </a>
          <!-- <a href="https://api.whatsapp.com/send?phone=965{{info?.supportPhone}}" class="link-icon px-2" target="_blank">
            <i class="fa fa-whatsapp"></i>
          </a> -->
        </div>
      </div>
      <img routerLink=".." *ngIf="!hideLogo" class="logo-small cursor-pointer" [src]="info?.logoPic"
        style="max-height: 45px;min-width: 45px; min-height: 45px;">
      <div class="d-flex align-items-center justify-content-center">
        <div class='px-2'>
        <app-cart-icon></app-cart-icon>
        </div>
        <a class="lang-switcher link-icon" (click)='toggleLang()'><i class="im im-ayin t-large"></i></a>
      </div>

    </div>
    <div class="top-menu color-white" *ngIf="expanded">
      <ul>
        <!-- <li class="my-2" *ngIf="!isLoggedIn()"><a routerLink='/register' class="color-white close-menu">{{'Register' | translate}}</a></li> -->
        <li class="my-2" *ngIf="!isLoggedIn()"><a [routerLink]='["/login"]' class="color-white close-menu">{{'Login / Register' | translate}}</a></li>
        <!-- <li class="my-2" *ngIf="isLoggedIn()"><a routerLink='/profile' class="color-white close-menu">{{'Account Info' | translate}}</a></li> -->
        <li class="my-2" *ngIf="isLoggedIn()"><a routerLink='/my-orders' class="color-white close-menu">{{'My Orders' | translate}}</a></li>
        <li class="my-2" ><a routerLink='/track-order' class="color-white close-menu">{{'Track Your Order' | translate}}</a></li>
        <li class="my-2"><a routerLink='/info' class="color-white close-menu">{{'Our Branches' | translate}}</a></li>
        <li class="my-2" *ngIf="isLoggedIn()"><a  class="color-white close-menu cursor-pointer" (click)="logout()">{{'Sign Out' | translate}}</a></li>
        <li class="my-2" *ngIf="isLoggedIn()"><a routerLink='/my-addresses' class="color-white close-menu">{{'Delivery Addresses' | translate}}</a></li>
      </ul>

    </div>
    <div class="py-2 d-flex align-items-center border-bottom color-primary" *ngIf="screenTitle">
      <div class="pt-2 cursor-pointer" (click)="goBack()">
        <i class="px-1 far fa-angle-left fa-2x"></i>
        <i class="px-1"></i>
      </div>
      <div class="pt-2 f-boldes t-large">
        {{screenTitle | translate}}
      </div>
    </div>
  </div>
</div>
<!-- <a href="#sidenav" class="btn open">&#8801;</a> -->
<div class="sidenav" id="sidenav">
 
    <a href="#!" class="sidenav-close"><i class="fas fa-times"></i></a>

  <!-- <a href="javascript:void(0)" class="closebtn" onclick="closeNav()">&times;</a> -->
  <div class="text-center">

    <img src="../../assets/images/side-bg.jpg">
  </div>
  
    <ul>
        
      
        <li class="item active"><a href="#"> <i class="fas fa-clipboard-list px-2"></i>{{'Menu'| translate}} </a></li>
        <!-- <li class="item">
            <div class="select w100 border-0">
              <select>
                <option value="1"> الكويت 🇰🇼</option>
                <option value="2">السعوديه 🇸🇦</option>
                <option value="3">قطر 🇶🇦 </option>
                <option value="4">الامارات 🇦🇪</option>
                <option value="5"> سلطنة عمان 🇴🇲</option>

                <option value="6"> تركيا 🇹🇷</option>
                <option value="7">مصر 🇪🇬</option>
                <option value="8">العراق🇮🇶</option>
                <option value="9">المغرب 🇲🇦</option>
                <option value="10">ليبيا 🇱🇾</option>
                <option value="11">نيجيريا 🇳🇬 </option>
                

              </select>
          </div>
        </li> -->
        <li class="item"><a [routerLink]='["/about-us"]'> <i class="fas fa-info-circle px-2"></i> {{'About Us'| translate}}  </a></li>
        <li class="item"><a [routerLink]='["/login"]'> <i class="fas fa-user-alt px-2"></i> {{'Login / Register' | translate}}</a></li> 
    </ul>
    <ul class="social-media">
        
      
      <li class="item active"><a href="https://api.whatsapp.com/send?phone=965{{info?.supportPhone}}"> <i class="fab fa-whatsapp px-2"></i></a></li>
      <li class="item"><a href="#"><i class="fab fa-instagram px-2"></i> </a></li>
  
      
     
  </ul>
  <!-- <p class="text-center all-rights">جميع الحقوق محفوظة لشركة البيت الكويتي التركي</p> -->
  <p class="text-center">By <a href="https://www.techoffice.co/" class='color-blue'>TECHOFFICE</a></p>
</div>
<a href="#!" class="close sidenav-overlay"></a>

<script>
  function closeNav() {
  document.getElementById("#sidenav").style.width = "0";
 

}
</script>