<!--Only On Home Screen we should show this-->
<div class='page-container'>
<router-outlet></router-outlet>
</div>
<div class="page-side sm-hidden">
  <div class="page-side-inner">
    <!-- <div class="track-order">
            <a [routerLink]="['/track-order']" class="text-shadow">{{'Track Order' | translate}}</a>
          </div>
          <div class="truck-about-us"
          [style.display]="allowAboutUs ? 'block' : 'none'">
            <a [routerLink]="['/about-us']" class="text-shadow">{{'About Us' | translate}}</a>
          </div>
          <div class="lang-switcher top-55" *ngIf="useMultiVendor">
            <app-branch-selector
                    (branchesLoaded)="onBranchesLoaded($event)"

                    [(branch)]="branchId" [notPickup]="true" class="pull-heading-right"></app-branch-selector>
          </div> -->

    <!-- <div class="page-side-logo">
            <a href=""><img [src]="logo" alt=""></a>
        </div> -->

  </div>
</div>
<div class="ikn-modal">
  <ngx-smart-modal #mSoonestTime identifier="mSoonestTime" [dismissable]="true">
    <div class="sevice-type-modal">


      <div class="margin-top-10"></div>
      <h1 class="ikn-modal-header font-extraBold">
        <span *ngIf="!isPickupService">{{'Soonest Delivery Time' | translate}}</span>
        <span *ngIf="isPickupService">{{'Soonest Pickup Time' | translate}}</span>
      </h1>
      <div class="margin-top-10"></div>
      <div class="text-right p-d-10">

        <div class="ikn-modal-content odrer-type-selector-container text-center">
          <h2 class="font-normal">{{(expectedDeliveryTime) | iknDate:'daynear'}}</h2>
          <div *ngIf="!(expectedDeliveryTimeTo)">
            <h2 class="text-success font-normal">{{(expectedDeliveryTime) | iknDate:useDateOnly ? 'df' : 'dtf'}}</h2>
          </div>
          <div *ngIf="expectedDeliveryTimeTo">
            <h2 class="text-success font-normal">{{(expectedDeliveryTime) | iknDate:'df'}}</h2>
            <h3 class="time-note text-success" *ngIf="!useDateOnly">
              {{'From Time' | translate}} {{(expectedDeliveryTime) | iknDate:'tf'}}
              {{'To Time' | translate }} {{(expectedDeliveryTimeTo) | iknDate:'tf'}}
            </h3>
          </div>
          <div style="height:20px"></div>

        </div>
        <div class="form-group btn-container text-center">
          <button class="btn btn-success" style="width:100%" type="submit" (click)="closeSoonestTimeModal()">
            <span>{{'Confirm' | translate}}</span>
          </button>
        </div>

      </div>
    </div>
  </ngx-smart-modal>
</div>
<div>
  <app-ask-for-membership></app-ask-for-membership>
</div>
<script>
  initPage();
</script>