<div class="page-btn font-extraBold" *ngIf='cart?.cartItems?.length' routerLink='/cart'>
  <a href="javascript:{}"
    class="btn btn-primary d-flex justify-content-between align-items-center">

    <div>{{cart?.$totalItemsQty}}</div>
    <div class='flex-grow-1 text-center'>{{'Review Cart' | translate}}</div>
    <div>{{cart?.$subTotal | iknCurrency}} {{cart?.$truck?.defaultCountry?.currencyCode | iknLocalized}}</div>

  </a>
</div>
<app-top-header class="top-bar-container" [bgStyle]="'solid'" [screenTitle]="category?.categoryInfo?.title | iknLocalized"></app-top-header>
<div class="cat-clearfix">
  &nbsp;
</div>
<div class="cat-main-pic" *ngIf="category?.categoryInfo?.mainPic">
    <img [src]="category?.categoryInfo?.mainPic" />
</div>
<div class="page-content">
    <div iknLoader [isActive]='!truck' class="loader-container"></div>
    <!-- <div class="wrap">
        <div class="search">
            <button type="submit" class="search_button">
              <i class="fa fa-search"></i>
            </button>
            <input type="text" class="search_term font-extraBold" placeholder="{{
                    (availableItems  ?
                      'SEARCH_PLACE_HOLDER' :  'Search For your Items') | translate: {count: availableItems}
                    }}" [(ngModel)]="searchText">
          </div>
    </div> -->
    <div *ngFor="let item of items | filter: searchText">
        <app-menu-item-card [item]="item"></app-menu-item-card>
    </div>
</div>

<!-- <div class="page-btn">
    <a href="javascript:{}" (click)="startOrder()" class="btn btn-primary">
      <span *ngIf="!cart?.cartItems?.length">{{'Start Ordering' | translate}}</span>
      <span *ngIf="cart?.cartItems?.length">{{'Complete Order' | translate}}</span>

    </a>
    <app-footer></app-footer>
</div> -->
<app-extra-category-alert [(deliveryCharge)]="category?.categoryInfo.extraDeliveryCharge"
    [(deliveryTime)]="category?.categoryInfo.extraDeliveryTimeMinutes" class="pull-heading-right">
</app-extra-category-alert>


<app-footer></app-footer>
<br />
<br />
