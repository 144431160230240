<app-top-header  class='top-bar-container' bgStyle="solid" screenTitle="Who are we"></app-top-header>
<div class="page-content px-4 my-4">
    <div class="align-self-center   cursor-pointer" [routerLink]="['/info']">
        <div class="who-we-are-logo text-center">
          <img src="../../assets/images/side-bg.jpg">
        </div>

   
      </div>
      <div class="content mt-3">
          <p class="text-justify">
              Lorem ipsum dolor sit, amet consectetur adipisicing elit. Odio facilis eligendi libero quae eaque error, expedita suscipit fugit nulla reprehenderit natus, minus ad? Nulla ipsam odit blanditiis, ipsum culpa nesciunt!
          </p>
          <p class="text-justify">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Odio facilis eligendi libero quae eaque error, expedita suscipit fugit nulla reprehenderit natus, minus ad? Nulla ipsam odit blanditiis, ipsum culpa nesciunt!
        </p>
        <p class="text-justify">
            Lorem ipsum dolor sit, amet consectetur adipisicing elit. Odio facilis eligendi libero quae eaque error, expedita suscipit fugit nulla reprehenderit natus, minus ad? Nulla ipsam odit blanditiis, ipsum culpa nesciunt!
        </p>

      </div>
      <div class="content mt-3">

        <div class="tabs">
            <div class="tab">
              <input type="checkbox" id="chck1">
              <label class="tab-label" for="chck1">Item 1</label>
              <div class="tab-content">
                Lorem ipsum dolor sit amet consectetur, adipisicing elit. Ipsum, reiciendis!
              </div>
            </div>
            <div class="tab">
              <input type="checkbox" id="chck2">
              <label class="tab-label" for="chck2">Item 2</label>
              <div class="tab-content">
                Lorem ipsum dolor sit amet consectetur adipisicing elit. A, in!
              </div>
            </div>
          </div>
    </div>
</div>
<app-footer></app-footer>
