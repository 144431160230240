import { Component, OnInit } from '@angular/core';
import { ApiResult, LocalizedField, SBOptions, TruckBranch, TruckInfo } from '../models';
import { ApiService } from '../api.service';
import { UtilsService } from '../utils.service';

@Component({
  selector: 'app-truck-info',
  templateUrl: './truck-info.component.html',
  styleUrls: ['./truck-info.component.css']
})
export class TruckInfoComponent implements OnInit {
  truck: TruckInfo;
  branches: TruckBranch[];
  sb: SBOptions;
  expandedSections: any = {};
  showTerms=false;
  constructor(api: ApiService, public utils: UtilsService) {
    api.viewTruck().subscribe(t => {
      this.truck = t.truck


    });
    api.viewSBOptionsDetails().subscribe(d => {
      this.sb = d && d.simpleBrokerOptions;
      if(this.sb.terms.ar && this.sb.terms.en)
          this.showTerms = true;

      if (!d.hasMultipleBranches) {
        api.ViewTruckStatus().subscribe(st => {

          this.branches = [
            {
              id: -1,
              name: {
                ar: 'الفرع الرئيسي',
                en: 'Main Branch'
              },
              location: {
                location: st.lastKnownLocation.location,
                address: st.lastKnownLocation.address,
                contactPhone: d.supportPhone
              },
              status: st.status,
            }]
        });
      } else {
        api.viewTruckBranches(null).subscribe(d => {
          this.branches = d && d.items;
        });
      }
    })

    this.expandedSections.workingHours = true;
  }

  ngOnInit() {

  }
 


}
